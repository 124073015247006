import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

export const Section = styled.div`
  max-width: 940px;
  margin: 0 auto;
  padding: 40px;
  font-size: 18px;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section>
      <h1>KEIN TREFFER</h1>
      <p>
        Die von Ihnen aufgerufene Datei konnte nicht gefunden werden, bitte
        versuchen Sie es später noch einmal.
      </p>
    </Section>
  </Layout>
)

export default NotFoundPage
